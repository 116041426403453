import React, { PureComponent } from 'react'
import ReactMarkdown from 'react-markdown'
import { Image } from 'semantic-ui-react'
import { Post } from '../../../../domain/models'
import { InMemoryPostRepository } from '../../../secondaries/repository'
import { Page } from '../../components/Page'
import pic03 from '../../images/pic03.jpg'

interface IPostDetailContainerProps {
  filename: string
  match: {
    params: {
      uuid: string
    }
  }
  location: any
  setWrapperRef: any
  timeout: boolean
}

interface IPostDetailContainerState {
  post: Post | null
  text: string
}

export class PostDetailsContainer extends PureComponent<IPostDetailContainerProps, IPostDetailContainerState> {
  constructor(props) {
    super(props)

    this.state = {
      post: new Post('', '', '', '', '', '', ''),
      text: '',
    }
  }

  public async fetchFile() {
    const data = await import(`src/adapters/primaries/pages/blog${this.props.location.pathname}.md`)
    const response = await fetch(data.default)
    const text = await response.text()

    this.setState({
      text,
    })
  }

  public async componentDidMount() {
    const repository = new InMemoryPostRepository()
    const post = repository.one(this.props.match.params.uuid)
    this.setState({ post })
    await this.fetchFile()
  }

  public render() {
    if (this.state.post === null) {
      return (
        <div>
          <p>Loading...</p>
        </div>
      )
    }

    return (
      <Page>
        <div className='post-content'>
          <h2>{this.state.post.title}</h2>
          <p>{this.state.post.author}</p>
          <p>{this.state.post.creationDate}</p>
          <Image className='image main' src={pic03} alt='' wrapped ui={false} />
          <div className='chapeau'>
            <b>
              <p>{this.state.post.chapeau}</p>
            </b>
          </div>
          <ReactMarkdown children={this.state.text} />
        </div>
      </Page>
    )
  }
}
