import React, { FC } from 'react'

interface IFooterProps {
  timeout: boolean
}

export const Footer: FC<IFooterProps> = (props) => {
  return (
    <footer id='footer' style={props.timeout ? { display: 'none' } : {}}>
      <div className='copyright'>
        <p>
          &copy; Theme: Dimension{' '}
          <a
            href='https://github.com/codebushi/gatsby-starter-dimension/blob/master/src/pages/index.js'
            target='_blank'
          >
            By codebushi
          </a>
          <br />
          <a href='https://github.com/tongyeouki' target='_blank'>
            @tongyeouki
          </a>
        </p>
      </div>
    </footer>
  )
}
