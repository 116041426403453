import * as React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

export const Nav = () => {
  const { t } = useTranslation()

  return (
    <nav>
      <ul>
        <li>
          <Link to={'/'}>
            <Button primary>{t('menu.home')}</Button>
          </Link>
        </li>
        <li>
          <Link to={'/posts'}>
            <Button primary>{t('menu.posts')}</Button>
          </Link>
        </li>
        <li>
          <Link to={'/'}>
            <Button primary>{t('menu.news')}</Button>
          </Link>
        </li>
      </ul>
    </nav>
  )
}
