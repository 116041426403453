import React from 'react'

import { Nav } from '../pages/blog/Nav'
import { Footer } from './Footer'
import { Layout } from './Layout'

export const Page = (props) => {
  return (
    <div>
      <Layout>
        <div className='body'>
          <div>
            <header id='header' className='post'>
              <Nav />
              <div className='content'>{props.children}</div>
            </header>
            <Footer timeout={false} />
          </div>
        </div>
      </Layout>
      <div id='bg' />
    </div>
  )
}
