import i18n from 'i18next'
import React from 'react'
import ReactMarkdown from 'react-markdown'

interface IArticleProps {
  filename: string
}

interface IArticleState {
  file: string
  language: string
}

export class Article extends React.Component<IArticleProps, IArticleState> {
  constructor(props: IArticleProps) {
    super(props)
    this.state = { file: '', language: i18n.language }
    this.fetchFile = this.fetchFile.bind(this, true)
  }

  public async componentDidMount() {
    await this.fetchFile()
  }

  public async componentDidUpdate() {
    if (this.state.language !== i18n.language) {
      this.setState({ language: i18n.language })
      await this.fetchFile()
    }
  }

  public async fetchFile() {
    const filename = this.props.filename
    const language = i18n.language
    const data = await import(`src/adapters/primaries/pages/static/${language}/${filename}.md`)
    const response = await fetch(data.default)
    const text = await response.text()

    this.setState({
      file: text,
    })
  }
  public render() {
    return (
      <div>
        <ReactMarkdown children={this.state.file} />
      </div>
    )
  }
}
