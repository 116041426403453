import * as React from 'react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface ILoginBoxState {
  email: string
  isLoggedIn: boolean
  password: string
}

export const LoginBox: FC<ILoginBoxState> = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = () => {
    console.log('Email: ' + email)
    console.log('Password: ' + password)
  }

  const handleEmailChange = (value) => {
    setEmail(value)
  }

  const handlePasswordChange = (value) => {
    setPassword(value)
  }

  return (
    <div className='inner-container'>
      <div className='input-group'>
        <input
          type='text'
          name='email'
          className='login-input'
          placeholder={t('login.username')}
          value={email}
          onChange={handleEmailChange}
        />
      </div>

      <div className='input-group'>
        <input
          type='password'
          name='password'
          className='login-input'
          placeholder={t('login.password')}
          value={password}
          onChange={handlePasswordChange}
        />
      </div>

      <button type='button' className='login-button' onClick={handleLogin}>
        {t('login.login')}
      </button>
    </div>
  )
}
