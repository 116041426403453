import i18n from 'i18next'
import React, { FC, useEffect, useState } from 'react'
import { Image } from 'semantic-ui-react'
import frFlag from '../images/fr.svg'
import enFlag from '../images/gb.svg'

const languages = {
  en: {
    code: 'en',
    flag: enFlag,
    isActive: false,
  },
  fr: {
    code: 'fr',
    flag: frFlag,
    isActive: false,
  },
}

interface ICountryFlagProps {
  code: string
  flag: string
  isActive: boolean
}

export const Language: FC = () => {
  const [hovered, setHovered] = useState(false)
  const language = i18n.language
  const toggleHover = () => setHovered(!hovered)

  return (
    <div onClick={toggleHover}>
      {hovered && (
        <div onMouseLeave={toggleHover}>
          {Object.keys(languages).map((key) => (
            <CountryFlag
              key={languages[key].code}
              code={languages[key].code}
              flag={languages[key].flag}
              isActive={hovered}
            />
          ))}
        </div>
      )}
      <div>
        {!hovered && <CountryFlag code={languages[language].code} flag={languages[language].flag} isActive={hovered} />}
      </div>
    </div>
  )
}

export const CountryFlag: FC<ICountryFlagProps> = (props: ICountryFlagProps) => {
  const [hovered, setHovered] = useState(false)
  const toggleHover = () => {
    if (props.code !== i18n.language) {
      setHovered(true)
    } else {
      setHovered(!hovered)
    }
  }

  const changeLanguage = async (language) => {
    await i18n.changeLanguage(language)
  }

  const isActive = () => {
    setHovered(props.isActive)
  }

  useEffect(() => {
    async function toggleActive() {
      await isActive()
    }
    toggleActive()
  }, [props.isActive])

  return (
    <Image
      className={hovered ? 'country-flag hovered' : 'country-flag'}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      onClick={() => changeLanguage(props.code)}
      src={props.flag}
      alt={props.code}
      title={props.code}
    />
  )
}
