export const TRANSLATIONS_EN = {
  contact: {
    message: `For any contact request, please send us an email to the following address: ${process.env.REACT_APP_ASSOCIATION_EMAIL}`,
  },
  login: {
    login: 'Login',
    password: 'Password',
    username: 'Email',
  },
  main: {
    subtitle: 'Development version',
    title: 'French Franz Liszt Association',
  },
  menu: {
    about: 'About Us',
    contact: 'Contact',
    home: 'Home',
    news: 'News',
    posts: 'Posts',
    subscribe: 'Subscribe',
  },
}
