import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Router, Switch } from 'react-router-dom'
import { IndexPage } from './adapters/primaries/pages'
import { PostDetailsContainer } from './adapters/primaries/pages/blog/PostDetailContainer'
import { PostsListContainer } from './adapters/primaries/pages/blog/PostsListContainer'
import { App } from './App'
import './index.css'
import { reportWebVitals } from './reportWebVitals'

const history = createBrowserHistory()

ReactDOM.render(
  <Router history={history}>
    <App>
      <Switch>
        <Route exact path='/' component={IndexPage} />
        <Route exact path='/posts' component={PostsListContainer} />
        <Route exact path='/posts/:uuid' component={PostDetailsContainer} />
      </Switch>
    </App>
  </Router>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
