import React from 'react'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Layout } from '../components/Layout'
import { Main } from '../components/Main'

import '../assets/scss/main.scss'

interface IIndexPageState {
  article: string
  articleTimeout: boolean
  isArticleVisible: boolean
  loading: string
  timeout: boolean
}

export class IndexPage extends React.Component<any, IIndexPageState> {
  private timeoutId: NodeJS.Timeout
  private wrapperRef: any
  constructor(props) {
    super(props)
    this.state = {
      article: '',
      articleTimeout: false,
      isArticleVisible: false,
      loading: 'is-loading',
      timeout: false,
    }
    this.handleOpenArticle = this.handleOpenArticle.bind(this)
    this.handleCloseArticle = this.handleCloseArticle.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  public componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: '' })
    }, 100)
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  public componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  public setWrapperRef(node) {
    this.wrapperRef = node
  }

  public handleOpenArticle(article) {
    this.setState({
      article,
      isArticleVisible: !this.state.isArticleVisible,
    })

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout,
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        articleTimeout: !this.state.articleTimeout,
      })
    }, 350)
  }

  public handleCloseArticle() {
    this.setState({
      articleTimeout: !this.state.articleTimeout,
    })

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout,
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        article: '',
        isArticleVisible: !this.state.isArticleVisible,
      })
    }, 350)
  }

  public handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.isArticleVisible) {
        this.handleCloseArticle()
      }
    }
  }

  public render() {
    return (
      <div>
        <Layout location={this.props.location}>
          <div className={`body ${this.state.loading} ${this.state.isArticleVisible ? 'is-article-visible' : ''}`}>
            <div id='wrapper'>
              <Header onOpenArticle={this.handleOpenArticle} timeout={this.state.timeout} />
              <Main
                timeout={this.state.timeout}
                articleTimeout={this.state.articleTimeout}
                article={this.state.article}
                onCloseArticle={this.handleCloseArticle}
                setWrapperRef={this.setWrapperRef}
              />
              <Footer timeout={this.state.timeout} />
            </div>
          </div>
        </Layout>
        <div id='bg' />
      </div>
    )
  }
}
