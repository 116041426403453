import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Image } from 'semantic-ui-react'
import { Post } from '../../../../domain/models'
import pic03 from '../../images/pic03.jpg'

interface IPostListingItemProps {
  post: Post
}

export const PostListItem2: FunctionComponent<IPostListingItemProps> = ({ ...props }) => {
  return (
    <div className='post-content'>
      <Link to={'/posts/' + props.post.uuid}>
        <Image className='image main' src={pic03} alt='' wrapped ui={false} />
        <h2>{props.post.title}</h2>
        <h3>{props.post.author}</h3>
        <p>{props.post.creationDate}</p>
        <p>{props.post.chapeau}</p>
      </Link>
    </div>
  )
}
