import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import liszt01 from '../images/liszt01.jpg'
import '../translations/i18n'
import { Language } from './CountryFlag'

interface IHeaderProps {
  timeout: boolean
  onOpenArticle: any
}

export const Header: FC<IHeaderProps> = (props) => {
  const [isShown, setIsShown] = useState(false)
  const { t } = useTranslation()

  return (
    <header id='header' style={props.timeout ? { display: 'none' } : {}}>
      <div className='logo' onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
        {!isShown && <img className='header-logo' src={liszt01} alt='' />}
        {isShown && (
          <span
            className='icon fa-sign-in'
            onClick={() => {
              props.onOpenArticle('login')
            }}
          />
        )}
      </div>
      <div className='content'>
        <div className='main'>
          <div className='inner'>
            <h1>{t('main.title')}</h1>
            {process.env.REACT_APP_ENV === 'dev' && <h3>{t('main.subtitle')}</h3>}
          </div>
          <Language />
        </div>
      </div>
      <nav>
        <ul>
          <li>
            <button
              onClick={() => {
                props.onOpenArticle('about')
              }}
            >
              {t('menu.about')}
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                props.onOpenArticle('liszt')
              }}
            >
              Liszt
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                props.onOpenArticle('subscribe')
              }}
            >
              {t('menu.subscribe')}
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                props.onOpenArticle('contact')
              }}
            >
              {t('menu.contact')}
            </button>
          </li>
        </ul>
      </nav>
    </header>
  )
}
