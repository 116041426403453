import { IPostRepository } from '../../domain/gateways'
import { Post } from '../../domain/models'

const PostsList = [
  new Post(
    'abcdef',
    '2021-01-01 12:00:00',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    'John Doe',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam molestie nunc lectus. Mauris at ipsum lacinia orci tempus pellentesque. Vestibulum dapibus pharetra metus ut malesuada. Suspendisse neque felis, malesuada nec cursus ac, lacinia nec libero.',
    'In at tincidunt ipsum. Cras laoreet sit amet enim sit amet pretium. Vivamus sapien nisi, interdum eget ipsum a, mollis cursus ex. Phasellus pellentesque bibendum finibus. Maecenas tincidunt maximus molestie. Curabitur porttitor dui diam, vitae aliquam dui pellentesque sed. Suspendisse et blandit tellus. Phasellus in tempor dui. Quisque blandit eleifend dolor, scelerisque aliquet ipsum placerat eget. Donec nec posuere ex. Donec vitae condimentum magna. Fusce tincidunt ipsum vel mattis bibendum. Nullam sit amet nisi vel magna pretium scelerisque. Nulla efficitur posuere viverra. Nulla sit amet iaculis erat, eget tristique ipsum.\n',
    'Whatever'
  ),
  new Post(
    'ghijk',
    '2021-01-02 14:31:00',
    'Nam ac nunc arcu',
    'John Smith',
    'Nam ac nunc arcu. Cras pretium nunc et sollicitudin cursus. In mattis faucibus venenatis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.',
    'Blank',
    'Whatever'
  ),
]

export class InMemoryPostRepository implements IPostRepository {
  public one(uuid: string): Post | null {
    const result = PostsList.find((post) => post.uuid === uuid)
    if (!result) {
      return null
    }
    return result
  }

  public all(): Post[] {
    return PostsList
  }
}
