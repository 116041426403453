import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import liszt01 from '../images/liszt01.jpg'
import lisztLithography from '../images/liszt-lithography.jpeg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'
import '../translations/i18n'
import { Article } from './Article'
import { LoginBox } from './LoginBox'

interface IMainProps {
  article: string
  articleTimeout: boolean
  onCloseArticle: any
  setWrapperRef: any
  timeout: boolean
}

export const Main: FC<IMainProps> = (props) => {
  const { t } = useTranslation()
  const close = (
    <div
      className='close'
      onClick={() => {
        props.onCloseArticle()
      }}
    />
  )

  return (
    <div ref={props.setWrapperRef} id='main' style={props.timeout ? { display: 'flex' } : { display: 'none' }}>
      <article
        id='login'
        className={`${props.article === 'login' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''}`}
        style={{ display: 'none' }}
      >
        <LoginBox email={''} isLoggedIn password={''} />
        {close}
      </article>

      <article
        id='about'
        className={`${props.article === 'about' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''}`}
        style={{ display: 'none' }}
      >
        <h2>{t('menu.about')}</h2>
        <span className='image main'>
          <img src={lisztLithography} alt='' />
        </span>
        <Article filename='presentation' />
        {close}
      </article>

      <article
        id='liszt'
        className={`${props.article === 'liszt' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''}`}
        style={{ display: 'none' }}
      >
        <h2>Franz Liszt</h2>
        <span className='image main'>
          <img src={liszt01} alt='' />
        </span>
        <Article filename='liszt-biography' />
        {close}
      </article>

      <article
        id='news'
        className={`${props.article === 'news' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''}`}
        style={{ display: 'none' }}
      >
        <h2>{t('menu.news')}</h2>
        <span className='image main'>
          <img src={pic03} alt='' />
        </span>
        <Article filename='news' />
        {close}
      </article>

      <article
        id='subscribe'
        className={`${props.article === 'subscribe' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''}`}
        style={{ display: 'none' }}
      >
        <h2>{t('menu.subscribe')}</h2>
        <span className='image main'>
          <img src={pic03} alt='' />
        </span>
        <Article filename='subscribe' />
        {close}
      </article>

      <article
        id='contact'
        className={`${props.article === 'contact' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''}`}
        style={{ display: 'none' }}
      >
        <div>
          <h2>{t('menu.contact')}</h2>
          <p>{t('contact.message')}</p>
          <ul className='icons'>
            <li>
              <a href='https://www.facebook.com/brunomoysan' className='icon fa-facebook' target='_blank'>
                <span className='label'>Twitter</span>
              </a>
            </li>
          </ul>
          {close}
        </div>
      </article>
    </div>
  )
}
