export class Post {
  constructor(
    public uuid: string,
    public creationDate: string,
    public title: string,
    public author: string,
    public chapeau: string,
    public content: string,
    public category: string
  ) {}
}
