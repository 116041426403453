import React, { PureComponent } from 'react'
import { Post } from '../../../../domain/models'
import { InMemoryPostRepository } from '../../../secondaries/repository'
import { PostListItem2 } from './PostListItem2'
import { Page } from '../../components/Page'

interface IState {
  posts: Post[]
}

export class PostsListContainer extends PureComponent<any, IState> {
  constructor(props) {
    super(props)

    this.state = {
      posts: [],
    }
  }

  public componentDidMount() {
    const repository = new InMemoryPostRepository()
    this.setState({ posts: repository.all() })
  }

  public render() {
    return (
      <Page>
        <div className='inner post-card'>
          <div>
            {this.state.posts.map((post) => (
              <PostListItem2 key={post.uuid} post={post} />
            ))}
            {this.state.posts.map((post) => (
              <PostListItem2 key={post.uuid} post={post} />
            ))}
            {this.state.posts.map((post) => (
              <PostListItem2 key={post.uuid} post={post} />
            ))}
          </div>
        </div>
      </Page>
    )
  }
}
